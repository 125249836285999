<script lang="ts" setup>
const modalStore = useModalStore();
const { updateNutrient } = useNutrientStore();
const { setValidation, required } = useValidation();

const props = defineProps({
  nutrient: {
    type: Object,
    default: () => {},
  },
});

const NUTRIENT_TYPE = [
  {
    label: 'Micro',
    value: 'micro',
  },
  {
    label: 'Macro',
    value: 'macro',
  },
];

const nutrimentData = reactive({
  label: props.nutrient.label,
  type: props.nutrient.type,
  symbol: props.nutrient.symbol,
  is_used_by_nectar: props.nutrient.is_used_by_nectar,
});

const validation = setValidation(nutrimentData, {
  label: { required },
  type: { required },
  symbol: { required },
});

async function handleUpdateNutriment() {
  await updateNutrient(props.nutrient.id, nutrimentData);
  modalStore.closeModal();
}
</script>
<template>
  <BaseModal title="Editer un nutriment">
    <form>
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.symbol.$model"
        label="Symbol"
        type="text"
        :validation="validation.symbol"
      />

      <BaseSelect
        v-model="validation.type.$model"
        label="Micro/Macro"
        :options="NUTRIENT_TYPE"
        :validation="validation.type"
        required
      />

      <BaseSwitch
        v-model="nutrimentData.is_used_by_nectar"
        active-text="Inclu dans Nectar Engine"
        inactive-text="Non-inclu dans Nectar Engine"
      />
    </form>

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleUpdateNutriment" />
    </template>
  </BaseModal>
</template>
